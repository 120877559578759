var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Positions "),_c('v-spacer'),_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"fa-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.list,"search":_vm.search,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.topics",fn:function(ref){
var item = ref.item;
return [_vm._l((item.topics),function(topic){return [_c('v-chip',{key:topic.id,staticClass:"ma-1",attrs:{"color":"primary","label":""}},[_vm._v(_vm._s(("" + (topic.name)))+" ")])]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 edit",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v("fa-edit ")])]}}],null,true)},[_c('span',[_vm._v("Edit Position")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"delete",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("fa-trash ")])]}}],null,true)},[_c('span',[_vm._v("Delete Position")])])]}}],null,true)}),_c('Dialog',{attrs:{"data":_vm.editedItem,"open":_vm.isDialogOpen,"width":"500px","height":"500px"},on:{"onClose":_vm.handleDialogClose}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }